.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50% );
    -webkit-transform: translate(-50% , -50% );
    -moz-transform: translate(-50% , -50% );
    -ms-transform: translate(-50% , -50% );
    -o-transform: translate(-50% , -50% );

    background-color: var(--primary-color);
    border: 1px solid #62b25018;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.348);
    padding: 6px;
    min-width: 250px;
    min-height: 150px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.modal{
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3px );
    transition: 0.1s;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -ms-transition: 0.1s;
    -o-transition: 0.1s;
}