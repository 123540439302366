.card{
    max-width: 345px;
    padding: 16px;
    border-radius: 24px !important;
    -webkit-border-radius: 24px !important;
    -moz-border-radius: 24px !important;
    -ms-border-radius: 24px !important;
    -o-border-radius: 24px !important;
    background-color: "#FFFFFF" !important;
}

.cardActionArea{
    border-radius: 24px !important;
    -webkit-border-radius: 24px !important;
    -moz-border-radius: 24px !important;
    -ms-border-radius: 24px !important;
    -o-border-radius: 24px !important;
    max-width: 345px;
}

.cardMedia{
    width: 100%;
    height: 100%;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
}

.cardContent{
    padding: 0 !important;
    padding-top: 12px !important;
}