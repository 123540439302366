@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
    color: var(--main-color);
    /* To Remove default tap highlight color */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

ul {
    list-style: none;
    padding: 0;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
    padding: 0 !important;
}


html,
body,
#root,
.app {
    scroll-behavior: smooth;
        
}   

body {
    /* background: #323232; */
    /* background: radial-gradient(circle, #1c1c1c 0%, rgb(26, 26, 26) 75%); */
    background: #f4f4f4 ;


    
}

/* 
    colors variables
*/

:root {
    --primary-color: #1c1c1c;
    --secondary-color: #ACACAC;
    --main-color: #62B250;
}

/* Scroll Bar --------- */
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #1c1c1c6a;

}

::-webkit-scrollbar-thumb {
    background: #62B250;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #62B250;
}

/* End Scroll Bar ---------*/

.read-more-button{
    color: #fff;
    display: inline;
    cursor: pointer;
    font-weight: 400;
    text-decoration: underline solid #ababab93 0.3px;
}
.display-text-group span{
    color: #ACACAC;
    font-size: 12px;
}