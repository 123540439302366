.parent{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:18px;
}

